<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
             <v-form @submit.prevent="saveInvoice">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="uved" rules="required|min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('uved.self')}}</div>
                                        <v-autocomplete v-model="uved" :items="uvedItems"
                                                        :error="!valid"
                                                        :search-input.sync="uvedSearching"
                                                        item-text="name" item-value="id"
                                                        :loading="loadingUveds"
                                                        :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                        :label="$t('uved.self')"
                                                        @click:clear="uvedSearching=null;uved=null;uvedItems=[];"
                                                        @click="clearUveds"
                                                        @click:append="redirectToUved(uved)"
                                                        autocomplete="off"
                                                        hide-details
                                                        :error-messages="errors"
                                                        :disabled="loadingUveds || disabledUveds"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                        clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                            </template>
                                        </v-autocomplete>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="amount" rules="required|numeric|min_value:0"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('amount')}}</div>
                                        <v-text-field v-model="amount" type="text"
                                                      :error="!valid"
                                                      :label="$t('amount')"
                                                      hide-details
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>



                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="number" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('invoice_number')}}</div>
                                        <v-text-field v-model="number" type="text"
                                                      :error="!valid"
                                                      :label="$t('invoice_number')"
                                                      hide-details
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <v-dialog ref="dateDialogContract" v-model="dateDialogContract" width="290px">
                                        <v-date-picker ref="pickerDateContract" v-model="date_invoice"
                                                       first-day-of-week="1" :locale="lang"
                                                       @change="dateDialogContract = false">
                                        </v-date-picker>
                                    </v-dialog>
                                    <ValidationProvider ref="dateContract" rules="required|date_format"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('date_invoice')}}</div>
                                        <v-text-field v-model="date_invoice"
                                                      :error="!valid"
                                                      :label="$t('date_invoice')"
                                                      readonly
                                                      @click.stop="dateDialogContract = !dateDialogContract"
                                                       @click:clear="date_invoice = null"
                                                      hide-details
                                                      :error-messages="errors"
                                                      :disabled="loading"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                      clearable></v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="12">
                                    <ValidationProvider ref="deal"  rules="min:1"
                                                        v-slot="{ errors, valid }">
                                        <div class="input_label">{{$t('deal.self')}}</div>
                                        <v-autocomplete v-model="deal" :items="dealItems"
                                                        :error="!valid"
                                                        :search-input.sync="dealSearching"
                                                        item-text="number" item-value="id"
                                                        :loading="loadingDeals"
                                                        :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                        :label="$t('deal.self')"
                                                        @click:clear="dealSearching=null;deal=null;dealItems=[];"
                                                        @click="clearDeals"
                                                        autocomplete="off"
                                                        hide-details
                                                        :error-messages="errors"
                                                        :disabled="loadingDeals || disabledDeals"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 input_text"
                                                        clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.number"></span>
                                                    <span class="ml-2 hidden-is-empty" v-text="((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '')"></span>
                                                </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <span class="search-list">
                                                    <span v-html="item.number"></span>
                                                    <span class="ml-2 hidden-is-empty" v-text="((typeof item.admin !== undefined && typeof item.admin.name !== undefined) ? item.admin.name : '')"></span>
                                                </span>
                                            </template>
                                        </v-autocomplete>

                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="4" v-if="false">
                                    <ValidationProvider ref="invoice_status" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="invoice_status" :disabled="loading"
                                                  :items="invoice_statusItems" :error-messages="errors"
                                                  prepend-icon="mdi-cash-check" :error="!valid"
                                                  color="primary" item-text="name" item-value="id"
                                                  :label="$t('invoice_status')" clearable></v-select>
                                    </ValidationProvider>
                                </v-col>


                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="request_scanned" rules="required"
                                                        v-slot="{ errors, valid }">


                                        <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataRequestScanned(data_request_scanned)">
                                            <v-btn
                                                elevation="1"
                                                @click="addRequestScanned"
                                            >
                                                {{ $t('attach_invoice_for_payment')}}
                                            </v-btn>
                                        </v-col>

                                        <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataRequestScanned(data_request_scanned)">
                                            <v-list subheader >
                                                <v-list-item class="px-0 mx-0"  @click="downloadFile(data_request_scanned)">
                                                    <v-list-item-icon>
                                                        <v-img
                                                            max-width="90"
                                                            :alt="`${data_request_scanned.file_name}`"
                                                            :src="data_request_scanned.url"
                                                            max-height="60"
                                                        ></v-img>
                                                        <v-icon
                                                            x-large
                                                            v-if="!mimeTypeImage(data_request_scanned.file_type)">mdi-file-document-outline mdi-48px</v-icon>
                                                    </v-list-item-icon>

                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="data_request_scanned.file_name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="(data_request_scanned.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                                        <v-btn
                                                            icon
                                                            @click="clearRequestScanned"
                                                        >
                                                            <v-icon> mdi-delete-outline</v-icon>
                                                        </v-btn>

                                                    </v-list-item-icon>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-file-input v-model="request_scanned"
                                                      class="px-0 mx-0"
                                                      id="request_scanned_input"
                                                      hide-input
                                                      prepend-icon=""
                                                      accept=".pdf"
                                                      :disabled="loading"
                                                      :error="!valid"
                                                      @change="previewRequestScanned"
                                        ></v-file-input>

                                    </ValidationProvider>
                                </v-col>

                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="answer_scanned" rules="min:1"
                                                        v-slot="{ errors, valid }">



                                        <v-col class="px-0 mx-0" cols="12" sm="12" v-if="!showDataAnswerScanned(data_answer_scanned)">
                                            <v-btn
                                                elevation="1"
                                                @click="addAnswerScanned"
                                            >
                                                {{ $t('attach_payment_invoice')}}
                                            </v-btn>
                                        </v-col>

                                        <v-col class="px-0 mx-0 py-0 my-0" cols="12" sm="12" v-if="showDataAnswerScanned(data_answer_scanned)">
                                            <v-list subheader >
                                                <v-list-item class="px-0 mx-0"  @click="downloadFile(data_answer_scanned)">
                                                    <v-list-item-icon >

                                                                <v-icon
                                                                    x-large
                                                                    >
                                                                    mdi-file-document-outline mdi-48px
                                                                </v-icon>

                                                    </v-list-item-icon>

                                                    <v-list-item-content>
                                                        <v-list-item-title v-text="data_answer_scanned.file_name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="(data_answer_scanned.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                                    </v-list-item-content>

                                                    <v-list-item-icon class="d-flex align-self-center mt-2">
                                                        <v-btn
                                                            :error="!valid"

                                                            icon
                                                            @click="clearAnswerScanned"
                                                        >
                                                            <v-icon> mdi-delete-outline</v-icon>
                                                        </v-btn>

                                                    </v-list-item-icon>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-file-input v-model="answer_scanned"
                                                      class="px-0 mx-0"
                                                      id="answer_scanned_input"
                                                      hide-input prepend-icon=""
                                                      accept=".pdf"
                                                      :disabled="loading"
                                                      :error="!valid"
                                                      @change="previewAnswerScanned"
                                        ></v-file-input>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row v-if="false">
                                <v-col class="py-0 d-flex justify-start" cols="12" sm="12" v-if="can(['administrator'])">
                                    <v-switch v-model="deleted"
                                              :disabled="loading || !can(['administrator'])"
                                              :readonly="!can(['administrator'])"
                                              :label="deleted ? $t('administrator_deleted') : $t('administrator_remove')"
                                              color="red" hide-details></v-switch>
                                </v-col>
                            </v-row>

                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogAdd"

                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>
                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>
    </v-container>
</template>
<script>

    import {ValidationObserver, ValidationProvider} from 'vee-validate'
    import {mapGetters} from "vuex"
    import debounce from "lodash/debounce"


    export default {
        name: 'InvoiceForm',
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        inject: ['forceRerender'],
        data() {
            return {
                dialogForm: true,
                heading: null,
                progress: 0,
                number: null,
                date_invoice: null,
                dateDialogContract: false,
                amount: null,
                loading: false,
                deleted: false,
                invoice_status: null,
                invoice_statusItems: [],
                company: null,
                companyItems: [],
                companySearching: null,
                loadingCompanies: false,

                loadingClients: false,
                request_scanned: null,
                answer_scanned: null,
                data_request_scanned: {},
                data_answer_scanned: {},
                new_answer_scanned: 0,
                new_request_scanned: 0,


                deal_uuid:null,



                uved: null,
                uvedItems: [],
                uvedSearching: null,
                loadingUveds: false,
                disabledUveds: false,
                deal: null,
                dealItems: [],
                dealSearching: null,
                loadingDeals: false,
                disabledDeals: false,

            }
        },
        computed: {
            ...mapGetters(['lang']),

        },
        watch: {
            uvedSearching: debounce(function (val) {
                if (val && !this.uved) {
                    this.getUveds(val)
                }
            }, 500),
            dealSearching: debounce(function (val) {
                if (val && !this.deal) {
                    this.getDeals(val)
                }
            }, 500),
        },
        mounted() {
            this.getInvoiceStatuses()
            this.checkCreate();
        },
        methods: {
            closeDialogAdd(){
                if(window.history.length > 2){
                    this.$router.back()
                }
                else{
                    this.$router.push({
                        name: 'invoice',
                    })
                }
            },
            showDataRequestScanned(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            showDataAnswerScanned(item){
                if(item){
                    if(item.file_name){
                        return true;
                    }
                    else{
                        return false;
                    }
                }
                return false;
            },
            checkCreate() {
                if (this.$route.name === "invoice.edit") {
                    this.heading = this.$t('invoice_editing')
                    if (this.$route.params.id) {
                        this.getInvoice(this.$route.params.id)
                    }
                }
                else
                {
                    if (this.$route.params.deal_uuid) {
                        this.getDeal(this.$route.params.deal_uuid)
                    }
                    else if (this.$route.params.uved_uuid) {
                        this.getUved(this.$route.params.uved_uuid)
                    }
                    this.heading = this.$t('invoice_creation')
                }
            },
            async getInvoiceStatuses() {
                let params = {};
                params.filter = 'all';
                await this.$http
                    .get(`admin/invoice_status`, {
                        params: params,
                    })
                    .then(res => {
                        this.invoice_statusItems = res.body.data
                    })
                    .catch(err => {
                        this.invoice_statusItems = [];
                        this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                    });
            },
            async getInvoice(id) {
                var _this = this;
                this.progress = 0
                this.loading = true
                this.id = id
                await this.$http
                    .get(`admin/invoice/${id}`, {
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.data_request_scanned = res.body.data.request_scanned
                        this.data_answer_scanned = res.body.data.answer_scanned
                        this.amount = res.body.data.amount
                        this.deleted = res.body.data.deleted
                        this.number = res.body.data.number
                        this.phoneRaw = res.body.data.phone
                        this.date_invoice = res.body.data.date_invoice
                        let uved = res.body.data.uved
                        if (uved && uved.id) {
                            this.uved = uved.id
                            this.uvedItems = [res.body.data.uved]
                            this.disabledUveds = true
                        } else {
                            this.uvedItems = []
                        }
                        let deal = res.body.data.deal
                        if (deal && deal.id) {
                            this.deal = deal.id
                            this.dealItems = [res.body.data.deal]
                            this.disabledDeals = true
                        } else {
                            this.dealItems = []
                        }
                        this.invoice_status = res.body.data.invoice_status
                        if(res.body.data.request_scanned_base64){
                            this.request_scanned  = new File([atob(res.body.data.request_scanned_base64)], res.body.data.request_scanned.file_name, {
                                type: "application/pdf",
                            })
                        }
                       if(res.body.data.answer_scanned_base64){
                           this.answer_scanned  = new File([atob(res.body.data.answer_scanned_base64)], res.body.data.answer_scanned.file_name, {
                               type: "application/pdf",
                           })
                       }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('failed_to_get_invoice'))
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            },
            async saveInvoice() {

                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()

                if (this.deal) {
                    formData.append('deal', this.deal)
                }
                if (this.uved) {
                    formData.append('uved', this.uved)
                }

                if (this.new_answer_scanned) {
                    formData.append('new_answer_scanned', 1)
                }
                if (this.new_request_scanned) {
                    formData.append('new_request_scanned', 1)
                }
                if (this.deleted) {
                    formData.append('deleted', 1)
                }

                if (this.data_request_scanned.size) {
                    formData.append('request_size', this.data_request_scanned.size)
                }
                if (this.data_request_scanned.file_name) {
                    formData.append('request_file_name', this.data_request_scanned.file_name)
                }
                if (this.data_request_scanned.file_type) {
                    formData.append('request_file_type', this.data_request_scanned.file_type)
                }

                if (this.data_answer_scanned && this.data_answer_scanned.size) {
                    formData.append('answer_size', this.data_answer_scanned.size)
                }
                if (this.data_answer_scanned && this.data_answer_scanned.file_name) {
                    formData.append('answer_file_name', this.data_answer_scanned.file_name)
                }
                if (this.data_answer_scanned && this.data_answer_scanned.file_type) {
                    formData.append('answer_file_type', this.data_answer_scanned.file_type)
                }

                if (this.amount) {
                    formData.append('amount', this.amount)
                }
                if (this.date_invoice) {
                    formData.append('date_invoice', this.date_invoice)
                }

                if (this.invoice_status) {
                    if (this.invoice_status.id) {
                        formData.append('invoice_status', this.invoice_status.id)
                    } else {
                        formData.append('invoice_status', this.invoice_status)
                    }
                }


                if (this.number) {
                    formData.append('number', this.number)
                }



                if (this.company) {
                    if (this.company.id) {
                        formData.append('company', this.company.id)
                    } else {
                        formData.append('company', this.company)
                    }
                }

                if (this.request_scanned) {
                    formData.append('request_scanned', this.request_scanned)
                }
                if (this.answer_scanned) {
                    formData.append('answer_scanned', this.answer_scanned)
                }

                    if (this.$route.params.id) {
                        await this.$http
                            .put(`admin/invoice/${this.$route.params.id}`, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                progress(e) {
                                    if (e.lengthComputable) {
                                        _this.progress = Math.round((e.loaded / e.total) * 100)
                                    }
                                }
                            })
                            .then(res => {
                                this.$toastr.success(this.$t('invoice_has_been_updated'))
                                if (res && res.body && res.body.data && res.body.data.exist_translations) {
                                    this.exist_translations = res.body.data.exist_translations
                                    this.all_translations = res.body.data.all_translations
                                }
                                //this.forceRerender()
                            })
                            .catch(err => {
                                this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                                if (err && err.body && err.body.message) {
                                    for (let prop in err.body.errors) {
                                        if (hasOwnProperty.call(err.body.errors, prop)) {
                                            if (_this.$refs[prop]) {
                                                if(prop ==='answer_scanned' || prop ==='request_scanned'){
                                                    this.$toastr.error(err.body.errors[prop][0])
                                                }
                                                _this.$refs[prop].setErrors([
                                                    err.body.errors[prop][0]
                                                ])
                                            }
                                        }
                                    }
                                    if (!err.body.errors) {
                                        this.$toastr.error(err.body.message)
                                    }
                                }
                            })
                            .finally(end => {
                                this.progress = 0
                                this.loading = false
                            })
                    }
                    else {
                        // Add
                        await this.$http
                            .post('admin/invoice', formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data'
                                },
                                progress(e) {
                                    if (e.lengthComputable) {
                                        _this.progress = Math.round((e.loaded / e.total) * 100)
                                    }
                                }
                            })
                            .then(res => {
                                this.$toastr.success(this.$t('invoice_has_been_added'))
                                if (res && res.body && res.body.data && res.body.data.id) {
                                    this.exist_translations = res.body.data.exist_translations
                                    this.all_translations = res.body.data.all_translations
                                    if(this.deal_uuid && this.deal_uuid!==''){
                                        this.$router.push({
                                            name: 'deal.show',
                                            params: {
                                                id: this.deal_uuid
                                            }
                                        })
                                    }
                                    else{
                                        this.$router.push({
                                            name: 'invoice.edit',
                                            params: {
                                                id: res.body.data.id
                                            }
                                        })
                                    }

                                } else {
                                    this.$router.push({
                                        name: 'invoice'
                                    })
                                }
                                //this.forceRerender()
                            })
                            .catch(err => {
                                this.$toastr.error(this.$t('invoice_has_not_been_added'))
                                if (err && err.body && err.body.message) {
                                    for (let prop in err.body.errors) {
                                        if (hasOwnProperty.call(err.body.errors, prop)) {
                                            if (_this.$refs[prop]) {
                                                _this.$refs[prop].setErrors([
                                                    err.body.errors[prop][0]
                                                ])
                                            }
                                        }
                                    }
                                    if (!err.body.errors) {
                                        this.$toastr.error(err.body.message)
                                    }
                                }
                            })
                            .finally(end => {
                                this.progress = 0
                                this.loading = false
                            })
                    }

            },
            async getDeal(deal_uuid) {
                if (deal_uuid) {
                    this.loadingDeal = true
                    await this.$http
                        .get("admin/deal/"+deal_uuid)
                        .then(res => {
                            this.deal = res.body.data.id
                            this.dealItems = [res.body.data]
                            this.uved = res.body.data.uved.id
                            this.uvedItems = [res.body.data.uved]
                            this.disabledDeals = true;
                            this.disabledUveds = true;
                        })
                        .catch(err => {
                            this.dealItems = []
                            this.uvedItems = []
                            this.disabledDeals = false;
                            this.disabledUveds = false;
                        })
                        .finally(end => {
                            this.loadingDeal = false
                        })
                }
            },
            async getUved(uved_uuid) {
                if (uved_uuid) {
                    this.loadingUveds = true
                    await this.$http
                        .get("admin/uved/"+uved_uuid)
                        .then(res => {
                            this.uved = res.body.data.id;
                            this.uvedItems = [res.body.data]
                            this.disabledUveds = true;
                        })
                        .catch(err => {
                            this.disabledUveds = false;
                            this.uvedItems = []
                        })
                        .finally(end => {
                            this.loadingUveds = false
                        })
                }
            },

            previewRequestScanned() {
                let document = this.request_scanned;
                this.data_request_scanned = {}
                this.data_request_scanned.url = URL.createObjectURL(document)
                this.data_request_scanned.file_name = document.name
                this.data_request_scanned.blob =  document
                this.data_request_scanned.size = document.size
                this.data_request_scanned.file_type = document.type
                this.new_request_scanned = 1;
            },
            clearRequestScanned(){
                this.request_scanned = null
                this.data_request_scanned  = {}
            },
            addRequestScanned(){
                document.getElementById('request_scanned_input').click()
            },
            previewAnswerScanned() {

                let document = this.answer_scanned;
                console.log(document)
                this.data_answer_scanned = {}
                this.data_answer_scanned.url = URL.createObjectURL(document)
                this.data_answer_scanned.file_name = document.name
                this.data_answer_scanned.blob =  document
                this.data_answer_scanned.size = document.size
                this.data_answer_scanned.file_type = document.type
                this.new_answer_scanned = 1;
            },
            clearAnswerScanned(){
                this.answer_scanned = null
                this.data_answer_scanned  = {}
            },
            addAnswerScanned(){
                document.getElementById('answer_scanned_input').click()
            },

            downloadFile(data) {
                fetch(data.url)
                    .then((resp) => resp.blob())
                    .then((blob) => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement("a");
                        a.style.display = "none";
                        a.href = url;
                        a.download = data.file_name;
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                        this.$toastr.success(this.$t('file_download_started'))
                    })
                    .catch(() =>
                        this.$toastr.error(this.$t('file_download_error'))

                    );
            },


            clearUveds() {
                if (!this.uved) {
                    this.uvedItems = []
                }
            },
            redirectToUved(val) {
                if (val) {
                    this.$router.push({
                        name: 'uved.edit',
                        params: {
                            id: val
                        }
                    })
                }
            },
            async getUveds(str) {
                if (str) {
                    this.loadingUveds = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.uved = str
                    }
                    await this.$http
                        .get("admin/uved", {
                            params: params,
                        })
                        .then(res => {
                            this.uvedItems = res.body.data
                        })
                        .catch(err => {
                            this.uvedItems = []
                        })
                        .finally(end => {
                            this.loadingUveds = false
                        })
                }
            },

            clearDeals() {
                if (!this.deal) {
                    this.dealItems = []
                }
            },
            clearEmptyDealItems(items) {
                if (items && items.length > 0) {
                    this.deal = items.filter(item => typeof item === 'object')
                }
            },
            async getDeals(str) {
                if (str) {
                    this.loadingDeals = true
                    let params = {}
                    params.filter = 'search'
                    if (str !== 'undefined') {
                        params.deal = str
                    }
                    await this.$http
                        .get("admin/deal", {
                            params: params,
                        })
                        .then(res => {
                            this.dealItems = res.body.data
                        })
                        .catch(err => {
                            this.dealItems = []
                        })
                        .finally(end => {
                            this.loadingDeals = false
                        })
                }
            },



        }
    }
</script>
